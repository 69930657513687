import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function AboutUs() {
    return (
        <div id="main-wrapper">
                <div>
                    <AgentHeader></AgentHeader>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card border-0 p-3 mb-4">
                                        <div className="container">
                                            <div className="bg-white shadow-md rounded p-4">
                                                <h2 className="text-6">About RINI Travel Connect</h2>
                                                <p>Welcome to RINI Travel Connect, where we redefine the travel experience by combining the joy of exploration with a commitment to sustainable and eco-friendly practices. At RINI Travel Connect, we believe in providing you with not just a trip, but a journey that respects our planet and communities.</p>
                                                <p>Your trusted partner in sustainable and seamless travel experiences. At RINI Travel Connect, we understand that obtaining the right visas can be a crucial aspect of your journey. That's why we go the extra mile to ensure your travel dreams come true, providing not just flights, holidays, and hotels, but also hassle-free visa services.</p>
                                                <div className="row mt-4 mb-2">
                                                    <div className="col-md-12">
                                                        <div className="featured-box style-1">
                                                            <div className="featured-box-icon text-primary"></div>
                                                            <h3>Our Mission:</h3>
                                                            <p>At RINI Travel Connect, our mission is to make travel a force for good. We are dedicated to curating unforgettable experiences that not only enrich your life but also contribute to the well-being of the environment and local communities. We aim to create a sustainable travel ecosystem that promotes responsible tourism.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="featured-box style-1">
                                                            <div className="featured-box-icon text-primary"></div>
                                                            <h3>What Sets Us Apart:</h3>
                                                            <ul className="p-3">
                                                                <li className="mb-3"><b>Eco-Friendly Travel:</b> We prioritize eco-friendly accommodations, transportation, and activities to minimize our carbon footprint. By choosing RINI Travel Connect, you contribute to the preservation of the planet's natural beauty.</li>
                                                                <li className="mb-3"><b>Community Engagement:</b> We believe in the transformative power of travel to create positive change. Our itineraries often include opportunities for community engagement, fostering cultural exchange and supporting local businesses.</li>
                                                                <li className="mb-3"><b>Destination Weddings with a Purpose:</b> Planning your dream destination wedding? We specialize in crafting sustainable and breathtaking destination weddings that not only celebrate your love but also contribute to the conservation and well-being of the chosen destination.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="featured-box style-1">
                                                            <div className="featured-box-icon text-primary"></div>
                                                            <h3>Our Services:</h3>
                                                            <ul className="p-3">
                                                                <li className="mb-3"><b>Flights:</b> RINI Travel Connect offers a wide range of flight options, connecting you to destinations near and far. Choose from various airlines and travel classes to find the perfect fit for your journey.</li>
                                                                <li className="mb-3"><b>Holidays:</b> Whether you seek an adventurous escape, a relaxing beach retreat, or a cultural immersion, our holiday packages cater to every traveler's desires. Explore our carefully curated itineraries designed for sustainable and memorable experiences.</li>
                                                                <li className="mb-3"><b>Hotels:</b> Discover eco-friendly and sustainable accommodations that align with our commitment to responsible tourism. We handpick hotels that prioritize environmental conservation and community engagement.</li>
                                                                <li className="mb-3"><b>Destination Weddings:</b> Plan your dream destination wedding with RINI Travel Connect, where each celebration is crafted with love and sustainability in mind. We work closely with local partners to create unforgettable moments while preserving the beauty of the chosen location</li>
                                                                <li className="mb-3"><b>Visa Assistance for Multiple Destinations:</b> Whether you're exploring a single country or embarking on a multi-country adventure, our visa services cover a wide range of destinations. Count on us to simplify the visa process for your chosen locations.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h2 className="text-6 mb-3">Join Us in Greening Your Travels:</h2>
                                                <div className="row">
                                                    <div className="col-md-12 col-md-3">
                                                        <p>Embark on a journey with RINI Travel Connect, where every adventure is an opportunity to make a positive impact. Whether you're a solo traveler, a couple, or a group, we invite you to explore the world responsibly with us.</p>
                                                        <p>At RINI Travel Connect, we don't just sell trips; we create sustainable travel experiences that leave a lasting imprint on your heart and the world. Travel with purpose, travel with RINI Travel Connect.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
        </div>
    )
}